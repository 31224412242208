<template>
  <section class="page">
    <div class="container">
      <div class="q-wrapper">
        <div class="d-none d-lg-none d-xl-block">
          <img src="@/assets/img/q.png" alt="" />
        </div>

        <div class="accordion" id="accordionExample">
          <h3>Questions fréquemment posées</h3>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Quand faut-il faire appel à un cabinet de recouvrement?
              </button>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Il est essentiel de faire appel à un cabinet de recouvrement dès qu'une facture est en
                souffrance. Plus tôt vous agirez, plus grandes seront vos chances de récupérer vos paiements.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Quels sont les avantages d'externaliser le recouvrement?
              </button>
            </div>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Faire appel à un cabinet de recouvrement permet de libérer des ressources internes de votre
                entreprise, de préserver la trésorerie et d'améliorer le délai moyen de paiement.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Quelles sont vos procédures de recouvrement?
              </button>
            </div>
            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Les procédures de recouvrement de Gayssot sont faites sur mesures et adaptées à chaque client,
                aussi bien à l'amiable qu’en marque blanche ou en nom propre.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Comment vous démarquez-vous des autres cabinets?
              </button>
            </div>
            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Par notre capacité à proposer des solutions modulables et sur-mesures; notre capacité à
                préserver le lien commercial entre notre client et son débiteur (28 % de nos clients sont des
                anciens débiteurs).
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Comment engager une procédure judiciaire de recouvrement avec Gayssot?
              </button>
            </div>
            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Nos juristes étudient chaque dossier avec minutie en tenant compte du contexte, de la
                solvabilité du débiteur ainsi que nos chances de réussite afin de privilégier l’action
                judiciaire la plus adaptée.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                Est-ce que le recouvrement est compatible avec une assurance crédit?
              </button>
            </div>
            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Oui, le recours à une société de recouvrement est compatible avec une assurance-crédit et peut
                même l'optimiser de plusieurs façons: En traitant les impayés des clients non assurés, ceux
                que l'assureur exclut. En gérant les impayés des clients assurés en non-dénommés, sachant que
                généralement seulement 60 % d'entre eux sont indemnisés. En intervenant sur les impayés des
                clients dénommés dans le délai de déclaration de 60 jours à l'assurance.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                Combien coûtent vos services?
              </button>
            </div>
            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Le taux d’honoraire appliqué est calculé en fonction du montant global des factures confiées,
                leurs nombres, leurs anciennetés et la localisation des débiteurs. Chaque action judiciaire
                envisagée fait l’objet d’un devis détaillé transmis au client pour approbation avant le
                lancement de l’action en justice. Pour obtenir votre taux d’honoraire personnalisé, cliquez
                ici
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Gayssot a-t-il les ressources informatiques nécessaires pour se connecter à mes outils
                comptables (logiciels, CRM, ERP, etc.).
              </button>
            </div>
            <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Nous nous adaptons à tous les outils existants. Nous sommes capables de faire du sur-mesure et
                de gérer de gros volumes de transactions. Nous disposons d’un logiciel propriétaire qui est
                modulable et personnalisable.
              </div>
            </div>
          </div>
        </div>
      </div>

      <NosExperts />
    </div>
  </section>
</template>

<script>
import NosExperts from "@/components/shared/Nos-experts.vue";

export default {
  head: {
    title: "FAQ",
  },

  components: { NosExperts },
};
</script>

<style lang="scss" scoped>
section {
  background: #f1f1f1;
}

.q-wrapper {
  display: flex;
  gap: 20px;

  h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 32px;
  }

  img {
    border-radius: 16px;
    height: 693px;
  }
}

.accordion {
  width: 100%;
}

.accordion-item {
  border-bottom: 2px solid #969696;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}

.accordion-body {
  color: $black;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.accordion-item:last-of-type {
  border-bottom: none;
}

.accordion-button {
  padding: 24px 16px;
  font-weight: 500;
  font-size: 18px;

  &::after {
    background-image: url("@/assets/img/icon/arrow.svg");
  }
}

.accordion-button:not(.collapsed)::after {
  background-image: url("@/assets/img/icon/arrow.svg");
}

d-none d-lg-none d-xl-block .accordion-button:not(.collapsed) {
  background-color: inherit;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: inherit;
}
</style>
